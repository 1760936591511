import { handleRequestBlog } from '../../utils/axios';

export const findCommentAPI = async (data) => {
  const config = {
    url: '/comment/search',
    method: 'POST',
    data
  };
  return handleRequestBlog(config);
};

export const createCommentAPI = async (data) => {
  const config = {
    url: '/comment/',
    method: 'POST',
    data
  };

  return handleRequestBlog(config);
};

export const updateCommentAPI = async (data) => {
  const config = {
    url: '/comment/',
    method: 'PUT',
    data
  };

  return handleRequestBlog(config);
};

export const updateLikeAPI = async (id) => {
  const config = {
    url: `/comment/${id}/like`,
    method: 'PUT'
  };
  return handleRequestBlog(config);
};

export const deleteCommentAPI = async (id) => {
  const config = {
    url: `/comment/${id}`,
    method: 'DELETE'
  };
  return handleRequestBlog(config);
};

export const deleteCommentsAPI = async (ids) => {
  const config = {
    url: `/comment/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequestBlog(config);
};
