import { handleRequest } from '../../utils/axios';

export const findBookAudioAPI = async (data) => {
  const config = {
    url: '/book-audio/search',
    method: 'POST',
    data,
  };
  return handleRequest(config);
};

export const getBookAudioByIdAPI = async (id) => {
  const config = {
    url: `/book-audio/${id}`,
    method: 'GET',
  };
  return handleRequest(config);
};
