import { handleRequest } from '../../utils/axios';

export const findBookAPI = async (data) => {
  const config = {
    url: '/book/search/active',
    method: 'POST',
    data,
  };
  return handleRequest(config);
};

export const getBookBySlugAPI = async (slug) => {
  const config = {
    url: `/book/slug/${slug}`,
    method: 'GET'
  };
  return handleRequest(config);
};