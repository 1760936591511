import { handleRequestBlog } from '../../utils/axios';

export const findBookmarkAPI = async (data) => {
  const config = {
    url: '/bookmark/search',
    method: 'POST',
    data
  };
  return handleRequestBlog(config);
};

export const createBookmarkAPI = async (data) => {
  const config = {
    url: '/bookmark/',
    method: 'POST',
    data
  };

  return handleRequestBlog(config);
};

export const deleteBookmarkAPI = async (id) => {
  const config = {
    url: `/bookmark/${id}`,
    method: 'DELETE',
  };
  return handleRequestBlog(config);
};

export const getBookmarkAPI = async (postId) => {
  const config = {
    url: `/bookmark/${postId}`,
    method: 'GET'
  };
  return handleRequestBlog(config);
};