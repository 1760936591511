import { handleRequest } from '../../utils/axios';

export const findBookmarkAPI = async (data) => {
  const config = {
    url: '/book-bookmark/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const createBookmarkAPI = async (data) => {
  const config = {
    url: '/book-bookmark/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};

export const deleteBookmarkAPI = async (id) => {
  const config = {
    url: `/book-bookmark/${id}`,
    method: 'DELETE',
  };
  return handleRequest(config);
};

export const getBookmarkAPI = async (exerciseId) => {
  const config = {
    url: `/book-bookmark/${exerciseId}`,
    method: 'GET'
  };
  return handleRequest(config);
};