import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// slices
import bookReducer from './slices/book/audiobook.book.slice';
import bookAudioReducer from './slices/book/audiobook.bookAudio.slice';
import bookmarkReducer from './slices/book/audiobook.bookmark';
import bookTagReducer from './slices/book/audiobook.tag.slice';
import userReducer from './slices/book/audiobook.user';

import blogBookmarkReducer from './slices/blog/blog.bookmark';
import blogCommentReducer from './slices/blog/blog.comment';
import blogForumPostReducer from './slices/blog/blog.forumPost';
import blogPostReducer from './slices/blog/blog.post';
import blogQuestionReducer from './slices/blog/blog.question';
import blogTagReducer from './slices/blog/blog.tag';

// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error']
};

const rootReducer = combineReducers({
  tag: persistReducer({ ...rootPersistConfig, key: "audiobook-tag" }, bookTagReducer),
  book: persistReducer({ ...rootPersistConfig, key: 'audiobook-book' }, bookReducer),
  bookAudio: persistReducer({ ...rootPersistConfig, key: 'audiobook-book-audio' }, bookAudioReducer),
  user: persistReducer({ ...rootPersistConfig, key: "audiobook-user" }, userReducer),
  bookmark: persistReducer({ ...rootPersistConfig, key: "audiobook-bookmark" }, bookmarkReducer),
  // blog

  // blog
  blogBookmark: persistReducer({ ...rootPersistConfig, key: "blog-bookmark" }, blogBookmarkReducer),
  blogPost: persistReducer({ ...rootPersistConfig, key: "blog-post" }, blogPostReducer),
  blogComment: persistReducer({ ...rootPersistConfig, key: "blog-comment" }, blogCommentReducer),
  blogTag: persistReducer({ ...rootPersistConfig, key: "blog-tag" }, blogTagReducer),
  blogForumPost: persistReducer({ ...rootPersistConfig, key: "blog-forum-post" }, blogForumPostReducer),
  blogQuestion: persistReducer({ ...rootPersistConfig, key: "blog-question" }, blogQuestionReducer)
});

export { rootPersistConfig, rootReducer };

