import { handleRequestBlog } from '../../utils/axios';

export const findPostAPI = async (data) => {
  const config = {
    url: '/post/search/active',
    method: 'POST',
    data
  };
  return handleRequestBlog(config);
};

export const getPostBySlug = async (slug) => {
  const config = {
    url: `/post/slug/${slug}`,
    method: 'GET'
  };
  return handleRequestBlog(config);
};