import { handleRequestBlog } from '../../utils/axios';

export const findQuestionAPI = async (data) => {
  const config = {
    url: '/question/search/active',
    method: 'POST',
    data
  };
  return handleRequestBlog(config);
};

export const getQuestionById = async (id) => {
  const config = {
    url: `/question/${id}`,
    method: 'GET'
  };
  return handleRequestBlog(config);
};

export const createQuestionAPI = async (data) => {
  const config = {
    url: '/question/',
    method: 'POST',
    data
  };

  return handleRequestBlog(config);
};

export const updateQuestionAPI = async (data) => {
  const config = {
    url: '/question/',
    method: 'PUT',
    data
  };

  return handleRequestBlog(config);
};

export const deleteQuestionAPI = async (id) => {
  const config = {
    url: `/question/${id}`,
    method: 'DELETE'
  };
  return handleRequestBlog(config);
};

export const deleteQuestionsAPI = async (ids) => {
  const config = {
    url: `/question/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequestBlog(config);
};
