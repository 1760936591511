import { handleRequest } from '../../utils/axios';

export const findTagAPI = async (data) => {
  const config = {
    url: '/tag/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getTagBySlugAPI = async (slug) => {
  const config = {
    url: `/tag/slug/${slug}`,
    method: 'GET'
  };
  return handleRequest(config);
};