import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//
import viLocales from './vi.json';

// ----------------------------------------------------------------------

let lng = 'vi';

if (typeof localStorage !== 'undefined') {
  lng = localStorage.getItem('i18nextLng') || 'vi';
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      vi: { translations: viLocales },
      en: { translations: viLocales }
    },
    lng,
    fallbackLng: 'vi',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
