import { handleRequestBlog } from "../../utils/axios";

export const findBlogTagsAPI = async (data) => {
  const config = {
    url: '/tag/search',
    method: 'POST',
    data
  };
  return handleRequestBlog(config);
};

export const getBlogTagByIdAPI = async (id) => {
  const config = {
    url: `/tag/${id}`,
    method: 'GET'
  };
  return handleRequestBlog(config);
};
