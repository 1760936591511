import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark-reasonable.css';

// ----------------------------------------------------------------------

hljs.configure({
  languages: ['javascript', 'jsx', 'sh', 'bash', 'html', 'scss', 'css', 'json', 'java', 'c', 'cpp', 'python', 'php', 'sql', 'ruby', 'go', 'rust', 'swift', 'kotlin', 'dart', 'typescript', 'csharp', 'scala', 'haskell', 'r', 'perl', 'lua', 'elixir', 'clojure', 'groovy', 'powershell', 'matlab', 'vbnet', 'objectivec', 'cobol', 'fortran', 'abap', 'actionscript', 'ada', 'apex', 'autoit', 'awk', 'ceylon', 'crystal', 'd', 'dlang', 'erlang', 'fsharp', 'golang', 'haxe', 'julia', 'nim', 'ocaml', 'pascal', 'racket', 'reasonml', 'sas', 'scheme', 'smalltalk', 'verilog', 'vhdl', 'yaml', 'makefile', 'dockerfile', 'pla'
    , 'plaintext'],
});

if (typeof window !== 'undefined') {
  window.hljs = hljs;
}
