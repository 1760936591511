/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { findQuestionAPI } from '../../../service/blog/blog.question.service';

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  questions: [],
  question: null,
  search: {
    page: 0,
    size: 10,
    value: '',
    orders: [
      {
        order: 'desc',
        property: 'createdAt',
      },
    ],
    filterBys: {
      tagIds: null,
    },
  },
};

const slice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setQuestions(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.questions = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setQuestionSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    },
    setQuestion(state, action) {
      state.isLoading = false;
      state.question = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setQuestionSearch, setQuestion } = slice.actions;
// ----------------------------------------------------------------------

export function getQuestions() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { blogQuestion: { search: { tags, ...other } } } = getState();

    const resp = await findQuestionAPI({ ...other, value: `%${other.value}%` });
    if (resp.code === '200') dispatch(slice.actions.setQuestions(resp));
    else dispatch(slice.actions.hasError(resp));
  };
}
